.Image {
  width: 150%;
  object-fit: cover;
  border: solid white 3px;
  border-radius: 40px;
}

.StaggerBottom {
  margin-bottom: 20%;
}

@media screen and (max-width: 768px) {
  .Image {
    width: 100%;
    height: 500px;
  }
}
