.Team {
  background: radial-gradient(
      farthest-side at bottom left,
      #9651fe38,
      transparent
    ),
    radial-gradient(farthest-corner at bottom right, #9651fe38, transparent);
  color: white;
  padding: 10% 18% 0 18%;

  .TeamList {
    padding: 0;
    font-size: 35px;
    list-style: none;
  }
}
