@-webkit-keyframes backgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes backgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.Home {
  background: linear-gradient(246deg, #7141b9, #28153a, #2e1455, #5a1f79);
  background-size: 200% 200%;
  -webkit-animation: backgroundAnimation 10s ease infinite;
  -moz-animation: backgroundAnimation 10s ease infinite;
  animation: backgroundAnimation 10s ease infinite;

  color: #ffffff;
  padding: 0 18%;

  --margin-home: calc(10%);
}

.Container {
  position: relative;
  margin: auto;
  width: 80%;
  height: 100vh;
}

.Card {
  position: relative;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: "";
    z-index: -2;
    position: absolute;
    display: block;
    top: -45%;
    left: -20%;
    height: 19.5rem;
    width: 19.5rem;
    border-radius: 50%;
    background: linear-gradient(
      276.71deg,
      #daff01 -9.77%,
      #ac8ddf 30.75%,
      #9353ff 88.91%
    );
  }

  &::after {
    content: "";
    z-index: -2;
    position: absolute;
    display: block;
    top: 30%;
    right: -30%;
    height: 26.5rem;
    width: 26.5rem;
    border-radius: 50%;
    background: linear-gradient(330.74deg, #9353ff -6.98%, #daff01 138.38%);
  }
}

.Content {
  padding: 4rem;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 80px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(193.84px);

  .CardButtons {
    display: inline;

    Button {
      margin-right: 1rem;
    }
  }
}

.Section {
  padding: 6% 0;

  .SectionAssets {
    padding-left: 30px;
  }

  .SectionContent {
    text-align: left;
  }

  .Objectives {
    text-align: left;
    padding-left: 20%;
  }

  .SectionImage {
    max-width: 35%;
  }
}

.InlineSection {
  display: flex;
  justify-content: space-between;
}

.List {
  ol {
    list-style: none;
    counter-reset: item;
    padding: 3% 0;

    li {
      counter-increment: item;
      margin: 30px;
    }

    li:before {
      font-weight: bold;
      margin-right: 1rem;
      content: counter(item);
      background: var(--background-subdued);
      border-radius: 20%;
      color: white;
      width: 2rem;
      text-align: center;
      display: inline-block;
      font-size: var(--font-standard);
    }
  }
}

.FaqLink {
  font-weight: bold;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.AboutSubtitle {
  margin-bottom: 50px;
}

.ImageRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;

  .ImageColumn {
    -ms-flex: 25%;
    flex: 25%;
    max-width: 30.5%;
    height: 450px;

    img {
      border: solid white 3px;
      border-radius: 40px;
      height: 100%;
      vertical-align: middle;
      width: 100%;
    }
  }
}

.SponsorLogos {
  img {
    padding-top: 10px;
    padding-left: 10px;
  }

  .Clickable {
    cursor: pointer;
  }

  .SponsorGold > img {
    max-width: 45%;
    max-height: 200px;
  }

  .SponsorSilver > img {
    width: 35%;
  }

  .SponsorBronze > img {
    width: 15%;
  }

  .SponsorInKind {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 10%;

      &:not(:first-child) {
        padding-left: 30px;
      }
    }
  }
}

.PricingCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.FaqHelpText {
  font-size: var(--font-standard);
}

.OnlyShowOnMobile {
  max-width: 100%;
}

.MobileHome {
  height: 100vh;
  text-align: center;
  padding-top: 40%;
  margin: 0 calc(-1 * var(--margin-home));

  .MobileHomeText {
    padding-top: 10%;
    .CardButtons {
      padding-top: 10%;

      .Button {
        margin: 0 5%;

        button {
          padding: 10px 30px;
          margin: 5px 0;
        }
      }
    }
  }

  .MobileWaves {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.MobileAbout {
  margin-top: -40%;
  padding: 20% 0;

  .MobileAboutContent {
    position: relative;
    padding-top: 10%;

    .MobileObjectives {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.8);
      width: 80%;
      padding: 5%;
      border-radius: 20px;

      .MobileTitle {
        font-weight: bold;

        p {
          margin: 0;
        }
      }

      ol {
        margin: 0;

        li {
          margin: 10px 0;
        }
      }
    }

    .Circles {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, -50%);

      .fa-circle {
        padding: 0 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ImageColumn {
    -ms-flex: 30%;
    flex: 30%;
    max-width: 30%;
  }
}

@media screen and (max-width: 480px) {
  .Home {
    padding: 0 10%;

    .Container {
      width: 100%;
    }
  }

  .ImageColumn {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }

  .HideOnMobile {
    display: none;
  }

  .SponsorLogos {
    .SponsorSilver > img {
      width: 45%;
    }

    .SponsorBronze > img {
      width: 25%;
    }

    .SponsorInKind > img {
      width: 17%;
    }
  }
}

@media screen and (min-width: 481px) {
  .OnlyShowOnMobile {
    display: none;
  }
}
