.Paragraph {
  font-size: var(--font-subheading);
}

.Bold {
  font-weight: bold;
}

.Underline:hover {
  color: var(--primary-dark);
  background: linear-gradient(to right, #daff01 -5.36%, #994efc 112.5%) left
    bottom no-repeat;
  background-size: 100% 2px;
}

.CardText {
  color: #fff;
  font-size: var(--font-standard);
}
