.NavWrapper {
  width: 100%;
  position: fixed;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
  z-index: 10;

  &.Transparent {
    background-color: transparent;
  }

  &.Solid {
    background-color: var(--background-dark);
  }
}

.Navbar {
  padding: 1% 10%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.NavItemList {
  display: flex;
  align-items: center;
  flex-direction: row;
  float: right;
  list-style-type: none;
}

.NavItemList > li:last-child {
  display: none;
}

.NavbarLogo {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.HamburgerIcon {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--primary-dark);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
  color: white;

  &:hover {
    background-color: lighten(#9353ff, 10%);
  }
}

.SignUpButton {
  box-sizing: border-box;
  margin-top: 10px;
  position: relative;
  margin-left: auto;
  height: 63px;
  border-radius: 98px;
  padding-left: 60px;
  padding-right: 60px;
  border: 2px solid white;
  cursor: pointer;
  font-size: 20px;
  color: white;
  background: transparent;

  &::before {
    content: "";
    position: absolute;
    border-radius: 98px;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    background: linear-gradient(
      276.71deg,
      #daff01 -9.77%,
      #ac8ddf 30.75%,
      #9353ff 88.91%
    );
  }

  &:hover:before {
    animation: flow 0.5s ease-out;
    width: 100%;
  }
}

@keyframes flow {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media screen and (max-width: 1180px) {
  .HamburgerIcon {
    display: block;
  }

  .NavList > ul {
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: -15px;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: var(--background-dark);
    border-top: 1px solid black;
    display: none;
  }

  .NavItemList > li:last-child {
    display: list-item;
  }

  .Mobile > ul {
    display: block;
  }

  .SignUpButton {
    display: none;
  }
}
