.WildPage {
  height: 100vh;
  padding: 0 10%;
  color: white;
}

.Title {
  padding-top: 30vh;
  text-align: center;
}

.Link {
  color: white;

  &:hover {
    color: var(--primary-light);
  }
}
