html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  --primary-dark: #9353ff;
  --primary-light: #daff01;
  --background: #1b1b1b;
  --background-subdued: #494949;
  --background-light: #767676;
  --background-dark: #0d0b0d;

  --font-subheading: 1.8rem;
  --font-heading: 3rem;
  --font-standard: 1.2rem;

  margin: 0;
  font-family: "Poppins", sans-serif;
  background: var(--background-dark);
}

div {
  margin: 0;
  padding: 0;
}

.CenterText {
  text-align: center;
}

.Highlight {
  color: var(--primary-light);
}

@media screen and (max-width: 768px) {
  body {
    --font-subheading: 1.5rem;
    --font-heading: 2.5rem;
    --font-standard: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  body {
    --font-subheading: 1.3rem;
    --font-heading: 2rem;
    --font-standard: 1rem;
  }
}
