.PricingCard {
  display: block;
  width: 40%;
  max-width: 300px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  color: black;
  margin: 50px 50px 0 0;
  align-self: center;

  h1 {
    margin: 5px 0;
  }

  p {
    color: var(--background-subdued);

    &.HelpText {
      margin: 0;
    }
  }

  .MostPopular {
    display: inline-block;
    position: relative;
    left: 100%;
    transform: translateX(-100%);
    background: rgba(168, 117, 255, 0.3);
    border-radius: 20px;
    padding: 5px 16px;
    color: var(--primary-dark);
    font-weight: bold;
  }

  .Icon {
    color: #9353ff47;
    font-size: 25px;
    align-self: center;
  }

  .FeatureName {
    text-align: left;
    justify-self: start;
  }

  .Features {
    display: grid;
    grid-template-columns: 1fr 85%;
    margin: 10px;
  }
}

.PriceContainer {
  .PriceContainerContent {
    display: flex;
    align-items: center;

    .PriceExtras {
      font-size: 20px;
      font-weight: 400;
    }

    .Price {
      font-size: 45px;
      font-weight: 600;
      margin-right: 16px;
    }
  }
}

.Vip {
  color: white;
  background-color: #240060;

  p {
    color: white;
  }

  .Icon {
    color: white;
  }
}

@media screen and (max-width: 952px) {
  .PricingCard {
    width: 100%;
    margin: 50px 0 0 0;
    padding: 30px;

    .PriceContainerContent {
      .Price {
        font-size: 30px;
      }

      .PriceExtras {
        font-size: 15px;
      }
    }

    p.HelpText {
      margin: 10px 0;
    }
  }
}
