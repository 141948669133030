.Button {
  display: inline-block;

  align-items: center;

  button {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: var(--font-standard);
    font-weight: bold;
    border-radius: 100rem;
    padding: 10px 50px;

    background: transparent;
    background-repeat: no-repeat;
    transition: color 0.3s, background-size 0.3s, background-position 0s 0.3s;

    &:hover {
      cursor: pointer;
    }

    &.Primary:not(.Inverted) {
      color: var(--primary-light);
      border: 1px solid var(--primary-light);

      &:hover {
        color: black;
        background-color: var(--primary-light);
      }
    }

    &.Primary.Inverted {
      color: black;
      background-color: var(--primary-light);

      &:hover {
        color: var(--primary-light);
        border: 1px solid var(--primary-light);
        background-color: transparent;
      }
    }

    &.Secondary:not(.Inverted) {
      color: white;
      border: solid 1px white;

      &:hover {
        color: white;
        background-color: var(--primary-dark);
        border-color: var(--primary-dark);
      }
    }

    &.Secondary.Inverted {
      color: white;
      background-color: var(--primary-dark);
      border-color: var(--primary-dark);

      &:hover {
        color: white;
        border: solid 1px white;
        background-color: transparent;
      }
    }
  }
}
