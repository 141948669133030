.HashLink {
  text-decoration: none;
}

.NavItem {
  display: flex;
  align-items: center;
  font-size: var(--font-standard);
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  margin: 1rem;
  padding-bottom: 5px;
  background-image: linear-gradient(to right, #daff01 -5.36%, #994efc 112.5%);
  background-position: 0 100%;
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s;
  &:hover {
    cursor: pointer;
    color: var(--primary-dark);
    background-position: 100% 100%;
    background-size: 100% 2px;
  }
}

.Active {
  color: var(--primary-dark);
  background: linear-gradient(to right, #daff01 -5.36%, #994efc 112.5%) left
    bottom no-repeat;
  background-size: 100% 2px;
  transition: all 2s ease;
}
